.reel-wrap {
  .inner-wrapper {
    padding: 90px 30px 30px;
    height: 100%;

    @include respond-to(mobile) {
      padding: 60px 25px 25px;
    }
  }
  
  .reel-video {
      // background: black;
      // border: solid 2px #fff;
      height: 100%;
      width: 100%;
      margin: 0 auto;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .reel-video-wrapper {
    width: auto;
    height: 100%;
    position: absolute;

    @media all and (max-width: 767px) and (max-height: 767px) {
      width: 100%;
      height: auto;
    }

    .reel-video-proportions {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .reel-play-wrapper {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  .reel-play-button {
    background: $bgblue;
    border-radius: 60px;
    width: 80px;
    height: 80px;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "";
      display: block;
      width: 0; 
      height: 0; 
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 20px solid #fff;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  
  .reel-play-image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}