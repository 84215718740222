@font-face {
  font-family: "averta_stdregular";
  src: url("../fonts/Averta/avertastd-regular-webfont.eot");
  src: url("../fonts/Averta/avertastd-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/Averta/avertastd-regular-webfont.woff2") format("woff2"), url("../fonts/Averta/avertastd-regular-webfont.woff") format("woff"), url("../fonts/Averta/avertastd-regular-webfont.ttf") format("truetype"), url("../fonts/Averta/avertastd-regular-webfont.svg#averta_stdregular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "averta_stdsemibold";
  src: url("../fonts/Averta/avertastd-semibold-webfont.eot");
  src: url("../fonts/Averta/avertastd-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/Averta/avertastd-semibold-webfont.woff2") format("woff2"), url("../fonts/Averta/avertastd-semibold-webfont.woff") format("woff"), url("../fonts/Averta/avertastd-semibold-webfont.ttf") format("truetype"), url("../fonts/Averta/avertastd-semibold-webfont.svg#averta_stdsemibold") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "averta_stdbold";
  src: url("../fonts/Averta/avertastd-bold-webfont.eot");
  src: url("../fonts/Averta/avertastd-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/Averta/avertastd-bold-webfont.woff2") format("woff2"), url("../fonts/Averta/avertastd-bold-webfont.woff") format("woff"), url("../fonts/Averta/avertastd-bold-webfont.ttf") format("truetype"), url("../fonts/Averta/avertastd-bold-webfont.svg#averta_stdbold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "averta_stdextrabold";
  src: url("../fonts/Averta/avertastd-extrabold-webfont.eot");
  src: url("../fonts/Averta/avertastd-extrabold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/Averta/avertastd-extrabold-webfont.woff2") format("woff2"), url("../fonts/Averta/avertastd-extrabold-webfont.woff") format("woff"), url("../fonts/Averta/avertastd-extrabold-webfont.ttf") format("truetype"), url("../fonts/Averta/avertastd-extrabold-webfont.svg#averta_stdextrabold") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "icomoon";
  src: url("../fonts/Icons/icomoon.eot?gbyioz");
  src: url("../fonts/Icons/icomoon.eot?gbyioz#iefix") format("embedded-opentype"), url("../fonts/Icons/icomoon.ttf?gbyioz") format("truetype"), url("../fonts/Icons/icomoon.woff?gbyioz") format("woff"), url("../fonts/Icons/icomoon.svg?gbyioz#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e906";
}

.icon-toggle:before {
  content: "\e905";
}

.icon-tiktok:before {
  content: "\e902";
}

.icon-right-arrow:before {
  content: "\e903";
}

.icon-left-arrow:before {
  content: "\e904";
}

.icon-email:before {
  content: "\e900";
}

.icon-snapchat:before {
  content: "\e901";
}

.icon-facebook:before {
  content: "\ea90";
}

.icon-instagram:before {
  content: "\ea92";
}

.icon-twitter:before {
  content: "\ea96";
}

.icon-linkedin:before {
  content: "\eaca";
}

/*==========================================================================
   COLORS
 ========================================================================== */
/*==========================================================================
   MEDIA QUERIES // example @include respond-to(mobile) { }
 ========================================================================== */
/*
  @include respond-to(mobile) {}
  @include respond-to(tablet) {}
  @include respond-to(desktopSm) {}
  @include respond-to(desktopLg) {}
*/
/*==========================================================================
   FONTS
 ========================================================================== */
/*==========================================================================
   FUNCTIONS
 ========================================================================== */
/*==========================================================================
   MIXINS
 ========================================================================== */
/* reset
----------------------------------------------*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  line-height: 1;
  height: 100%;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

/* Base
----------------------------------------------*/
* {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
}

html.locked, body.locked {
  overflow: hidden;
}

/* Body settings
----------------------------------------------*/
body {
  overflow: hidden;
  font-family: "averta_stdregular", Helvetica, sans-serif;
  font-weight: lighter;
  color: #fff;
  background-color: #4AA7C6;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

.bg-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  background-color: #4AA7C6;
  opacity: 0;
  visibility: hidden;
}
.bg-overlay.active {
  opacity: 0.8;
  visibility: visible;
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}

.main {
  width: 100%;
  height: 100%;
}

p, li, dd {
  line-height: 1.5em;
}

::selection {
  background-color: #ddd;
}

::-webkit-input-placeholder {
  color: #ebebeb;
}

:-moz-placeholder {
  color: #ebebeb;
}

::-moz-placeholder {
  color: #ebebeb;
}

:-ms-input-placeholder {
  color: #ebebeb;
}

/* Headings
----------------------------------------------*/
h1 {
  font-size: 3.125em;
  line-height: 60px;
}

h2 {
  font-size: 3.125em;
  line-height: 55px;
  font-family: "averta_stdextrabold", Helvetica, sans-serif;
  font-weight: lighter;
}
@media all and (max-width: 767px) {
  h2 {
    font-size: 2.5em;
    line-height: 45px;
  }
}

h3 {
  font-size: 1.25em;
  line-height: 30px;
  font-family: "averta_stdsemibold", Helvetica, sans-serif;
  font-weight: lighter;
}
@media all and (max-width: 767px) {
  h3 {
    font-size: 1em;
    line-height: 22px;
  }
}

h4 {
  font-size: 1.25em;
  line-height: 30px;
}
@media all and (max-width: 767px) {
  h4 {
    font-size: 1em;
    line-height: 22px;
  }
}

h5 {
  font-size: 1.125em;
  line-height: 26px;
}
@media all and (max-width: 767px) {
  h5 {
    font-size: 1em;
    line-height: 22px;
  }
}

h6 {
  font-size: 1em;
  line-height: 24px;
}

p {
  font-size: 1.5625em;
  line-height: 35px;
  color: #fff;
}
@media all and (max-width: 767px) {
  p {
    font-size: 1.125em;
    line-height: 26px;
  }
}

.avboldup {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.5px;
  font-family: "averta_stdbold", Helvetica, sans-serif;
  font-weight: lighter;
  text-transform: uppercase;
}
@media all and (max-width: 767px) {
  .avboldup {
    font-size: 0.8125em;
    line-height: 13px;
  }
}

/* links
----------------------------------------------*/
a, a:link, a:visited {
  text-decoration: none;
  color: #fff;
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}

a:hover, a:active {
  text-decoration: none;
  color: #005173;
}
@media all and (max-width: 767px) {
  a:hover, a:active {
    color: #fff;
  }
}

/* Blockquote
----------------------------------------------*/
blockquote {
  font-size: 2.1875em;
  line-height: 45px;
  text-align: center;
  color: #fff;
}

/* Media 
----------------------------------------------*/
img, video {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

figcaption {
  font-size: 85%;
}

/* Lists
----------------------------------------------*/
ol, ul {
  list-style: none;
}

/* Page wrap
----------------------------------------------*/
.page-wrap {
  width: 100%;
  height: 0;
  top: 100%;
  left: 0;
  position: absolute;
  z-index: 10;
  overflow: hidden;
  -moz-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  text-align: center;
}
.page-wrap.open {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  top: 0;
  height: 100%;
}
.page-wrap .inner-wrapper {
  width: 100%;
  position: relative;
  top: 0;
  padding: 200px 25px 50px;
}
@media all and (max-width: 767px) {
  .page-wrap .inner-wrapper {
    padding: 120px 25px 50px;
  }
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
header ul li {
  display: inline-block;
  vertical-align: middle;
}
header .main-nav {
  float: left;
  padding: 30px;
}
@media all and (max-width: 767px) {
  header .main-nav {
    float: none;
    text-align: center;
    position: fixed;
    top: 0;
    right: 100%;
    background-color: #4AA7C6;
    width: 100%;
    height: 100%;
    z-index: 10;
    -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  }
}
@media all and (max-width: 767px) {
  header .main-nav.open {
    right: 0;
  }
}
@media all and (max-width: 767px) {
  header .main-nav ul li.nav-text-item {
    display: block;
    padding: 10px;
  }
}
@media all and (max-width: 767px) {
  header .main-nav ul li.nav-text-item a {
    font-family: "averta_stdsemibold", Helvetica, sans-serif;
    font-weight: lighter;
    text-transform: capitalize;
    font-size: 1.125em;
  }
}
header ul li.nav-text-item {
  margin-right: 6px;
}
@media all and (max-width: 767px) {
  header ul li.nav-text-item {
    margin-right: 0px;
  }
}
header ul li.nav-text-item.show-on-mobile {
  display: none;
}
@media all and (max-width: 767px) {
  header ul li.nav-text-item.show-on-mobile {
    display: block;
  }
}
@media all and (max-width: 767px) {
  header ul li.nav-text-item.hide-on-mobile {
    display: none;
  }
}
header ul li.nav-text-item.active a {
  opacity: 1 !important;
}
header ul li.nav-text-item.active a .text:after {
  width: 100%;
}
header ul li.nav-text-item.active .icon-close {
  opacity: 1 !important;
}
header ul li.nav-text-item a {
  position: relative;
}
header ul li.nav-text-item a .text {
  display: inline-block;
  position: relative;
}
header ul li.nav-text-item a .text.long {
  display: inline-block;
}
header ul li.nav-text-item a .text.short {
  display: none;
}
header ul li.nav-text-item a .text:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -5px;
  width: 0;
  height: 2px;
  background-color: #fff;
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
@media all and (max-width: 767px) {
  header ul li.nav-text-item a .text:after {
    display: none;
  }
}
header ul li.nav-text-item a:hover .text:after {
  background-color: #005173;
}
@media all and (max-width: 767px) {
  header ul li.nav-text-item a:hover .text:after {
    background-color: #fff;
  }
}
header ul li.nav-text-item a:hover .icon-close {
  color: #005173;
}
@media all and (max-width: 767px) {
  header ul li.nav-text-item a:hover .icon-close {
    color: #fff;
  }
}
header ul li.nav-text-item .icon-close {
  font-size: 12px;
  margin-left: 4px;
  vertical-align: baseline;
  opacity: 0;
  -moz-transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
@media all and (max-width: 767px) {
  header ul li.nav-text-item .icon-close {
    font-size: 11px;
    display: none;
  }
}
header .social-nav {
  float: right;
  padding: 30px;
}
@media all and (max-width: 767px) {
  header .social-nav {
    padding: 15px 20px 20px 0;
  }
}
@media all and (max-width: 568px) {
  header .social-nav {
    padding: 15px 16px 20px 0;
    display: flex;
    flex-wrap: wrap;
    max-width: 120px;
    justify-content: flex-end;
  }
}
header .social-nav ul li.nav-text-item {
  vertical-align: initial;
}
@media all and (max-width: 568px) {
  header .social-nav ul li.nav-text-item {
    padding: 0 0 8px;
  }
}
header .social-nav ul li.nav-text-item .icon-close {
  margin-left: 0px;
  margin-right: 4px;
}
header .social-nav ul li.nav-icon-item {
  margin-left: 12px;
  vertical-align: middle;
  display: inline-block;
}
header .social-nav ul li.nav-icon-item [class^=icon-], header .social-nav ul li.nav-icon-item [class*=" icon-"] {
  font-size: 17px;
}
header .social-nav ul li.nav-icon-item .icon-snapchat {
  margin: 0px 2px;
  font-size: 20px;
}
header .social-nav ul li.nav-icon-item .icon-instagram {
  margin-top: 3px;
}
header .social-nav ul li.nav-icon-item .icon-email {
  font-size: 14px;
}
header .social-nav ul li.nav-icon-item .icon-twitter {
  font-size: 16px;
}
@media all and (max-width: 767px) {
  header .social-nav ul li.nav-icon-item a {
    opacity: 1 !important;
  }
}

.hamburger {
  position: fixed;
  top: 22px;
  left: 22px;
  z-index: 120;
  display: none;
  cursor: pointer;
}
@media all and (max-width: 767px) {
  .hamburger {
    display: block;
  }
}
.hamburger.active span:nth-child(1) {
  transform: rotate(45deg) translate(3px, 3px);
  -webkit-transform: rotate(45deg) translate(3px, 3px);
  -moz-transform: rotate(45deg) translate(3px, 3px);
}
.hamburger.active span:nth-child(2) {
  opacity: 0;
}
.hamburger.active span:nth-child(3) {
  transform: rotate(-45deg) translate(4px, -4px);
  -webkit-transform: rotate(-45deg) translate(4px, -4px);
  -moz-transform: rotate(-45deg) translate(4px, -4px);
}
.hamburger span {
  display: block;
  width: 20px;
  height: 2px;
  background-color: #fff;
  margin: 3px 0;
}

.home-wrap {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0;
  overflow: hidden;
}
.home-wrap.open {
  overflow-y: auto;
  opacity: 1;
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.home-wrap .inner-wrapper {
  width: 100%;
  height: 100%;
  display: table;
}
.home-wrap .content-wrap {
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  padding: 100px 25px;
}
.home-wrap svg {
  height: 100%;
  width: auto;
}
.home-wrap h1 {
  height: 250px;
  margin-top: 30px;
  opacity: 0;
  -moz-transition: all 0.7s cubic-bezier(0.35, 1, 0.32, 1);
  -webkit-transition: all 0.7s cubic-bezier(0.35, 1, 0.32, 1);
  -o-transition: all 0.7s cubic-bezier(0.35, 1, 0.32, 1);
  transition: all 0.7s cubic-bezier(0.35, 1, 0.32, 1);
}
.home-wrap h1.animated {
  opacity: 1;
}
@media all and (min-width: 1300px) and (min-height: 700px) {
  .home-wrap h1 {
    height: 320px;
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .home-wrap h1 {
    height: 220px;
  }
}
@media all and (max-width: 767px) {
  .home-wrap h1 {
    height: 110px;
    margin-top: 0;
  }
}
.home-wrap h2 {
  opacity: 0;
  font-family: "averta_stdsemibold", Helvetica, sans-serif;
  font-weight: lighter;
  font-size: 1.5em;
  line-height: 30px;
  margin-top: 20px;
  -moz-transition: all 0.7s cubic-bezier(0.35, 1, 0.32, 1);
  -webkit-transition: all 0.7s cubic-bezier(0.35, 1, 0.32, 1);
  -o-transition: all 0.7s cubic-bezier(0.35, 1, 0.32, 1);
  transition: all 0.7s cubic-bezier(0.35, 1, 0.32, 1);
}
.home-wrap h2.animated {
  opacity: 1;
}
@media all and (max-width: 767px) {
  .home-wrap h2 {
    font-size: 1.125em;
    line-height: 24px;
    padding: 0 20px;
  }
}
.home-wrap .icon-sam {
  font-size: 250px;
}
@media all and (min-width: 1300px) and (min-height: 700px) {
  .home-wrap .icon-sam {
    font-size: 320px;
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .home-wrap .icon-sam {
    font-size: 220px;
  }
}
@media all and (max-width: 767px) {
  .home-wrap .icon-sam {
    font-size: 110px;
  }
}

.roles-wrap h3 {
  display: inline-block;
  font-family: "averta_stdregular", Helvetica, sans-serif;
  font-weight: lighter;
  border-bottom: solid 1px #fff;
  margin-bottom: 10px;
}
.roles-wrap ul {
  margin-bottom: 70px;
}
.roles-wrap ul li {
  font-family: "averta_stdextrabold", Helvetica, sans-serif;
  font-weight: lighter;
  font-size: 3.125em;
  line-height: 62px;
}
@media all and (max-width: 767px) {
  .roles-wrap ul li {
    font-size: 2.1875em;
    line-height: 45px;
  }
}
.roles-wrap ul li a {
  position: relative;
}
.roles-wrap ul li a:hover .icon-ext-link {
  opacity: 1;
  right: -40px;
}
.roles-wrap ul li a .icon-ext-link {
  color: #005173;
  opacity: 0;
  font-size: 18px;
  vertical-align: middle;
  position: absolute;
  top: 25px;
  right: -20px;
  -moz-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
@media all and (max-width: 767px) {
  .roles-wrap ul li a .icon-ext-link {
    display: none;
  }
}

.toggle-roles {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  text-align: center;
  -moz-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.toggle-roles.active {
  top: 0;
  opacity: 1 !important;
}
.toggle-roles.active .toggle-wrap {
  top: 0;
}
.toggle-roles.active .icon-toggle,
.toggle-roles.active .icon-toggle:hover {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  color: #fff;
}
.toggle-roles .toggle-wrap {
  position: absolute;
  top: 0;
  left: 0;
  padding: 29px 30px 30px 25px;
  -moz-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
@media all and (max-width: 767px) {
  .toggle-roles .toggle-wrap {
    padding: 17px 25px 20px 15px;
  }
}
.toggle-roles .icon-toggle {
  font-size: 30px;
  cursor: pointer;
  transform-origin: center center;
  -moz-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
@media all and (max-width: 767px) {
  .toggle-roles .icon-toggle {
    font-size: 20px;
  }
}

.bio-wrap .leap-helper {
  display: none;
}
.bio-wrap.open {
  left: 0;
  width: 100%;
}
.bio-wrap.open .timeline {
  left: 0;
}
.bio-wrap.open .leap-helper {
  display: block;
}
@media all and (max-width: 767px) {
  .bio-wrap.open {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.bio-wrap .inner-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  left: 0;
}
.bio-wrap .timeline-desc {
  width: 100%;
  height: 100%;
}
.bio-wrap .timeline-desc .slides {
  width: 100%;
  height: 100%;
}
.bio-wrap .timeline-desc .slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  padding-bottom: 50px;
}
.bio-wrap .timeline-desc .slide:first-child {
  left: 0;
}
.bio-wrap .timeline-desc .slide .inner-wrapper {
  width: 100%;
  height: 100%;
  display: table;
}
.bio-wrap .timeline-desc .slide .center-align {
  width: 100%;
  display: table-cell;
  vertical-align: middle;
}
.bio-wrap .timeline-desc .slide .desc {
  max-width: 500px;
  margin: 0 auto;
}
@media all and (max-width: 767px) {
  .bio-wrap .timeline-desc .slide .desc {
    max-width: 300px;
    padding: 0 25px;
  }
}
.bio-wrap .timeline-desc .slide p + p {
  margin-top: 30px;
}
.bio-wrap .helper {
  position: absolute;
  left: 50%;
  margin-left: -75px;
  margin-top: 3px;
  width: 150px;
}
.bio-wrap .helper span {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  color: #97C7D8;
}
.bio-wrap .helper .icon-left-arrow,
.bio-wrap .helper .icon-right-arrow {
  font-size: 24px;
}
.bio-wrap .helper.scroll-helper {
  bottom: 195px;
  opacity: 0.85;
}
@media all and (max-width: 767px) {
  .bio-wrap .helper.scroll-helper {
    bottom: 165px;
  }
}
.bio-wrap .helper.leap-helper {
  position: fixed;
  bottom: 45px;
  padding-left: 5px;
  cursor: pointer;
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.bio-wrap .helper.leap-helper:hover span {
  color: #005173;
}
.bio-wrap .helper.leap-helper.inactive {
  opacity: 0;
}
.bio-wrap .helper.leap-helper span {
  color: #fff;
}
@media all and (max-width: 767px) {
  .bio-wrap .helper.leap-helper {
    bottom: 25px;
  }
}
.bio-wrap .timeline {
  width: 100%;
  height: 100px;
  position: fixed;
  left: 100%;
  bottom: 90px;
  overflow: hidden;
  -moz-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
@media all and (max-width: 767px) {
  .bio-wrap .timeline {
    bottom: 70px;
  }
}
.bio-wrap .timeline .line-wrapper {
  position: absolute;
  top: 36px;
  left: 50%;
  padding-right: 50%;
}
.bio-wrap .timeline .line {
  width: 100%;
  height: 3px;
  background-color: #97C7D8;
}
.bio-wrap .timeline .line .progress {
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
}
.bio-wrap .timeline h4 {
  font-family: "averta_stdextrabold", Helvetica, sans-serif;
  font-weight: lighter;
  font-size: 1.5625em;
  line-height: 25px;
  color: #97C7D8;
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
@media all and (max-width: 767px) {
  .bio-wrap .timeline h4 {
    font-size: 22px;
    line-height: 22px;
  }
}
.bio-wrap .timeline .year-dot {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  width: 15px;
  height: 15px;
  background-color: #97C7D8;
  display: block;
  margin: 0 auto 20px;
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.bio-wrap .timeline .slide {
  position: absolute;
  top: 0;
  left: 110%;
  width: 100px;
  height: 80px;
  margin-left: -50px;
  text-align: center;
  cursor: pointer;
  padding-top: 30px;
}
.bio-wrap .timeline .slide:nth-child(1) {
  left: 50%;
}
.bio-wrap .timeline .slide:nth-child(2) {
  left: 70%;
}
@media all and (max-width: 767px) {
  .bio-wrap .timeline .slide:nth-child(2) {
    left: 90%;
  }
}
.bio-wrap .timeline .slide:nth-child(3) {
  left: 90%;
}
@media all and (max-width: 767px) {
  .bio-wrap .timeline .slide:nth-child(3) {
    left: 130%;
  }
}
.bio-wrap .timeline .slide:nth-child(4) {
  left: 110%;
}
@media all and (max-width: 767px) {
  .bio-wrap .timeline .slide:nth-child(4) {
    left: 170%;
  }
}
.bio-wrap .timeline .slide.active h4, .bio-wrap .timeline .slide:hover h4 {
  color: #fff;
}
.bio-wrap .timeline .slide.active .year-dot, .bio-wrap .timeline .slide:hover .year-dot {
  background-color: #fff;
}

.press-wrap h2,
.awards-wrap h2,
.bragging-rights-wrap h2 {
  margin-bottom: 120px;
}
@media all and (max-width: 767px) {
  .press-wrap h2,
.awards-wrap h2,
.bragging-rights-wrap h2 {
    margin-bottom: 80px;
  }
}
.press-wrap h3,
.awards-wrap h3,
.bragging-rights-wrap h3 {
  font-family: "averta_stdextrabold", Helvetica, sans-serif;
  font-weight: lighter;
  font-size: 1.25em;
  line-height: 25px;
  height: 70px;
}
@media all and (max-width: 767px) {
  .press-wrap h3,
.awards-wrap h3,
.bragging-rights-wrap h3 {
    font-size: 1em;
    line-height: 20px;
  }
}
.press-wrap p,
.awards-wrap p,
.bragging-rights-wrap p {
  font-size: 1em;
  line-height: 22px;
  margin-top: 20px;
}
@media all and (max-width: 767px) {
  .press-wrap p,
.awards-wrap p,
.bragging-rights-wrap p {
    font-size: 0.875em;
    line-height: 20px;
  }
}
.press-wrap .align-wrap,
.awards-wrap .align-wrap,
.bragging-rights-wrap .align-wrap {
  width: 100%;
  height: 100%;
  display: table;
}
@media all and (max-width: 767px) {
  .press-wrap .align-wrap,
.awards-wrap .align-wrap,
.bragging-rights-wrap .align-wrap {
    display: block;
  }
}
.press-wrap .align,
.awards-wrap .align,
.bragging-rights-wrap .align {
  width: 100%;
  display: table-cell;
  vertical-align: middle;
}
@media all and (max-width: 767px) {
  .press-wrap .align,
.awards-wrap .align,
.bragging-rights-wrap .align {
    display: block;
  }
}
.press-wrap .press-desc,
.press-wrap .awards-desc,
.awards-wrap .press-desc,
.awards-wrap .awards-desc,
.bragging-rights-wrap .press-desc,
.bragging-rights-wrap .awards-desc {
  max-width: 1100px;
  margin: 0 auto 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
.press-wrap .press-desc .pdesc,
.press-wrap .press-desc .adesc,
.press-wrap .awards-desc .pdesc,
.press-wrap .awards-desc .adesc,
.awards-wrap .press-desc .pdesc,
.awards-wrap .press-desc .adesc,
.awards-wrap .awards-desc .pdesc,
.awards-wrap .awards-desc .adesc,
.bragging-rights-wrap .press-desc .pdesc,
.bragging-rights-wrap .press-desc .adesc,
.bragging-rights-wrap .awards-desc .pdesc,
.bragging-rights-wrap .awards-desc .adesc {
  width: 200px;
  display: inline-block;
  vertical-align: top;
  padding: 0 10px 50px;
}
@media all and (max-width: 767px) {
  .press-wrap .press-desc .pdesc,
.press-wrap .press-desc .adesc,
.press-wrap .awards-desc .pdesc,
.press-wrap .awards-desc .adesc,
.awards-wrap .press-desc .pdesc,
.awards-wrap .press-desc .adesc,
.awards-wrap .awards-desc .pdesc,
.awards-wrap .awards-desc .adesc,
.bragging-rights-wrap .press-desc .pdesc,
.bragging-rights-wrap .press-desc .adesc,
.bragging-rights-wrap .awards-desc .pdesc,
.bragging-rights-wrap .awards-desc .adesc {
    width: 100%;
    max-width: 250px;
    padding: 0 25px 35px;
  }
}
.press-wrap .press-desc .pimage,
.press-wrap .press-desc .aimage,
.press-wrap .awards-desc .pimage,
.press-wrap .awards-desc .aimage,
.awards-wrap .press-desc .pimage,
.awards-wrap .press-desc .aimage,
.awards-wrap .awards-desc .pimage,
.awards-wrap .awards-desc .aimage,
.bragging-rights-wrap .press-desc .pimage,
.bragging-rights-wrap .press-desc .aimage,
.bragging-rights-wrap .awards-desc .pimage,
.bragging-rights-wrap .awards-desc .aimage {
  width: 100%;
  height: 80px;
}
@media all and (max-width: 767px) {
  .press-wrap .press-desc .pimage,
.press-wrap .press-desc .aimage,
.press-wrap .awards-desc .pimage,
.press-wrap .awards-desc .aimage,
.awards-wrap .press-desc .pimage,
.awards-wrap .press-desc .aimage,
.awards-wrap .awards-desc .pimage,
.awards-wrap .awards-desc .aimage,
.bragging-rights-wrap .press-desc .pimage,
.bragging-rights-wrap .press-desc .aimage,
.bragging-rights-wrap .awards-desc .pimage,
.bragging-rights-wrap .awards-desc .aimage {
    height: auto;
    padding: 10px 0;
  }
}
.press-wrap .press-desc img,
.press-wrap .awards-desc img,
.awards-wrap .press-desc img,
.awards-wrap .awards-desc img,
.bragging-rights-wrap .press-desc img,
.bragging-rights-wrap .awards-desc img {
  width: auto;
  max-width: 125px;
  max-height: 70px;
  margin: 0 auto;
}

.subscribe-wrap .heading-wrapper {
  margin: 0 auto 50px;
  max-width: 500px;
}
.subscribe-wrap .heading-wrapper p {
  font-size: 1.25em;
  line-height: 34px;
  margin-top: 10px;
}
@media all and (max-width: 767px) {
  .subscribe-wrap .heading-wrapper p {
    font-size: 1.125em;
    line-height: 22px;
  }
}

.subscribe-form {
  margin: 0 auto;
  max-width: 500px;
  text-align: left;
}
.subscribe-form .flex-inputs {
  display: flex;
  justify-content: center;
}
@media all and (max-width: 568px) {
  .subscribe-form .flex-inputs {
    display: block;
    margin: 0 auto;
  }
}
.subscribe-form .flex-inputs .subscribe-input-number {
  margin-left: -2px;
}
@media all and (max-width: 568px) {
  .subscribe-form .flex-inputs .subscribe-input-number {
    margin-top: -2px;
    margin-left: 0;
  }
}
.subscribe-form .subscribe-inputs input,
.subscribe-form .subscribe-inputs select {
  background-color: transparent;
  border: 2px solid white;
  border-radius: 0;
  color: white;
  height: 50px;
  font-size: 1em;
  padding: 15px;
  width: 100%;
}
.subscribe-form .subscribe-inputs input.mce_inline_error,
.subscribe-form .subscribe-inputs select.mce_inline_error {
  border: 2px solid white !important;
}
.subscribe-form .subscribe-inputs select {
  appearance: none;
}
.subscribe-form .subscribe-inputs .custom-select {
  position: relative;
}
.subscribe-form .subscribe-inputs .custom-select span {
  position: absolute;
  top: 13px;
  right: 12px;
  transform: rotate(90deg);
  font-size: 25px;
}
.subscribe-form .subscribe-inputs label {
  font-family: "averta_stdsemibold", Helvetica, sans-serif;
  font-weight: lighter;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
.subscribe-form .subscribe-inputs ::placeholder {
  color: rgba(255, 255, 255, 0.65);
}
.subscribe-form .subscribe-inputs .subscribe-input-name {
  margin-bottom: 20px;
}
@media all and (max-width: 568px) {
  .subscribe-form .subscribe-inputs .subscribe-input-name {
    width: 100%;
  }
}
.subscribe-form .subscribe-inputs .subscribe-input-name input {
  font-family: "averta_stdregular", Helvetica, sans-serif;
  font-weight: lighter;
}
.subscribe-form .subscribe-inputs .subscribe-input-email {
  width: 250px;
}
@media all and (max-width: 568px) {
  .subscribe-form .subscribe-inputs .subscribe-input-email {
    width: 100%;
  }
}
.subscribe-form .subscribe-inputs .subscribe-input-email input {
  font-family: "averta_stdregular", Helvetica, sans-serif;
  font-weight: lighter;
}
.subscribe-form .subscribe-inputs .subscribe-button {
  background-color: white;
  border: 0;
  color: #4AA7C6;
  height: 50px;
  font-size: 0.875em;
  width: 100%;
  margin-top: 30px;
}
@media all and (max-width: 568px) {
  .subscribe-form .subscribe-inputs .subscribe-button {
    width: 100%;
  }
}

#mc_embed_signup input.mce_inline_error,
#sk_embed_signup input.ske_inline_error {
  border: 2px solid white;
}

#mc_embed_signup div.mce_inline_error,
#sk_embed_signup div.ske_inline_error {
  font-family: "averta_stdregular", Helvetica, sans-serif;
  font-weight: lighter;
  background-color: transparent !important;
  font-size: 0.75em !important;
  text-align: left !important;
  color: white !important;
}

#mce-responses,
#ske-responses {
  display: block;
  padding: 15px 15px 0;
  text-align: center;
}

.reel-wrap .inner-wrapper {
  padding: 90px 30px 30px;
  height: 100%;
}
@media all and (max-width: 767px) {
  .reel-wrap .inner-wrapper {
    padding: 60px 25px 25px;
  }
}
.reel-wrap .reel-video {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reel-wrap .reel-video-wrapper {
  width: auto;
  height: 100%;
  position: absolute;
}
@media all and (max-width: 767px) and (max-height: 767px) {
  .reel-wrap .reel-video-wrapper {
    width: 100%;
    height: auto;
  }
}
.reel-wrap .reel-video-wrapper .reel-video-proportions {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.reel-wrap .reel-video-wrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.reel-wrap .reel-play-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.reel-wrap .reel-play-button {
  background: #4AA7C6;
  border-radius: 60px;
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.reel-wrap .reel-play-button::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 20px solid #fff;
  margin-left: 5px;
  cursor: pointer;
}
.reel-wrap .reel-play-image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}