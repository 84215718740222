$inactiveCol: #97C7D8;

.bio-wrap {

  .leap-helper {
    display: none;
  }

  &.open {
    // overflow: auto;
    // -webkit-overflow-scrolling:touch;
    // top:0;
    left:0;
    // height: 100%;
    width: 100%;

    .timeline {
      left:0;
    }

    .leap-helper {
      display: block;
    }

    @include respond-to(mobile) {
      overflow-x:auto;
      -webkit-overflow-scrolling:touch;
    }
  }

  .inner-wrapper {
    width:100%;
    height: 100%;
    position: relative;
    left:0;
    // top: 0;
  }

  .timeline-desc {
    width:100%;
    height: 100%;

    .slides {
      width: 100%;
      height: 100%;
    }

    .slide {
      width: 100%;
      height: 100%;
      position: absolute;
      top:0;
      left:100%;
      padding-bottom: 50px;

      &:first-child {
        left:0;
      }

      .inner-wrapper {
        width: 100%;
        height: 100%;
        display: table;
      }

      .center-align {
        width: 100%;
        display: table-cell;
        vertical-align: middle;
      }
      .desc {
        max-width: 500px;
        margin: 0 auto;
        @include respond-to(mobile) {
          max-width: 300px;
          padding:0 25px;
        }
      }
      p + p {
        margin-top: 30px;
      }
    }
  }

  .helper {
    position: absolute;
    left: 50%;
    margin-left: -75px;
    margin-top: 3px;
    width:150px;

    span {
      display: inline-block;
      vertical-align: middle;
      margin:0;
      color:$inactiveCol;
    }
    .icon-left-arrow,
    .icon-right-arrow {
      font-size: 24px;
    }
    &.scroll-helper {
      bottom: 195px; // 11.2019 change // 200px;
      opacity: .85;

      @include respond-to(mobile) {
        bottom: 165px; // 11.2019 change // 170px;
      }
    }
    &.leap-helper {
      position: fixed;
      bottom: 45px; // 11.2019 change // 50px;
      padding-left: 5px;  // 11.2019 change
      cursor: pointer;
      @include transition(all .35s cubic-bezier(.23, 1, .32, 1));

      &:hover span {
        color: $drkblue;
      }

      &.inactive {
        opacity: 0;
      }

      span {
        color: #fff;
      }

      @include respond-to(mobile) {
        bottom:  25px; // 11.2019 change //30px;
      }
    }
  }

  .timeline {
    width:100%;
    height: 100px;
    position: fixed;
    left:100%;
    bottom:90px;
    overflow: hidden;
    @include transition(all .5s cubic-bezier(.23, 1, .32, 1));

    @include respond-to(mobile) {
      bottom:70px;
    }

    .line-wrapper {
      position: absolute;
      top:36px;
      left:50%;
      padding-right: 50%;
    }
    .line {
      width: 100%;
      height: 3px;
      background-color:$inactiveCol;

      .progress {
        width: 0;
        height: 100%;
        position: absolute;
        top:0;
        left:0;
        background-color: #fff;
      }
    }

    h4 {
      @include AvertaExBld();
      font-size: emCal(25px);
      line-height: 25px;
      color:$inactiveCol;
      @include transition(all .35s cubic-bezier(.23, 1, .32, 1));

      @include respond-to(mobile) {
        font-size: 22px;
        line-height: 22px;
      }
    }
    .year-dot {
      @include border-radius(50px);
      width: 15px;
      height: 15px;
      background-color: $inactiveCol;
      display: block;
      margin: 0 auto 20px;
      @include transition(all .35s cubic-bezier(.23, 1, .32, 1));
    }
    .slide {
      position: absolute;
      top:0;
      left:110%;
      width:100px;
      height: 80px;
      margin-left: -50px;
      text-align: center;
      cursor: pointer;
      padding-top: 30px;

      &:nth-child(1) { left:50%; }
      &:nth-child(2) { left:70%;
        @include respond-to(mobile) { left:90%; }
      }
      &:nth-child(3) { left:90%;
        @include respond-to(mobile) { left:130%; }
      }
      &:nth-child(4) { left:110%;
        @include respond-to(mobile) { left:170%; }
      }

      &.active,
      &:hover {
        h4 { color:#fff; }
        .year-dot { background-color: #fff; }
      }
    }

  }
}