header {
  width:100%;
  position: fixed;
  top:0;
  left:0;
  z-index: 9999;
  // padding-left: 50px;

  @include respond-to(mobile) {
    // padding-left: 35px;
  }
  ul li {
    display: inline-block;
    vertical-align: middle;
  }

  .main-nav {
    float: left;
    padding:30px;

    @include respond-to(mobile) {
      float: none;
      text-align: center;
      position: fixed;
      top: 0;
      right: 100%;
      background-color: $bgblue;
      width: 100%;
      height: 100%;
      z-index: 10;
      @include transition(all .35s cubic-bezier(.23, 1, .32, 1));
    }

    &.open {
      @include respond-to(mobile) {
        right: 0;
      }
    }

    // @include respond-to(mobile) {
    //   padding:15px 0 20px 20px;
    // }

    // @media all and (max-width: 568px) {
    //   padding:15px 0 20px 16px;
      
    //   display: flex;
    //   flex-wrap: wrap;
    //   max-width: 160px;
    // }

    ul li.nav-text-item {
      // @media all and (max-width: 568px) {
      //   padding: 0 0 6px;
      // }
      @include respond-to(mobile) {
        display: block;
        padding: 10px;
      }

      a {
        @include respond-to(mobile) {
          @include AvertaSmBld();
          text-transform: capitalize;
          font-size: emCal(18px);
        }
      }
    }
  }

  ul li.nav-text-item {
    margin-right: 6px;

    @include respond-to(mobile) {
      margin-right: 0px;
    }

    // @media all and (max-width: 568px) {
    //   padding: 0 0 5px;
    // }
    &.show-on-mobile {
      display: none;

      @include respond-to(mobile) {
        display: block;
      }
    }

    &.hide-on-mobile {
      @include respond-to(mobile) {
        display: none;
      }
    }

    &.active{
      a {
        opacity: 1 !important;
      }
      a .text:after {
        width: 100%;
      }
      .icon-close {
        opacity: 1 !important;
      }
    }

    a {
      position: relative;

      .text {
        display: inline-block;
        position: relative;

        &.long {
          display: inline-block;
          // @include respond-to(mobile) {
          //   display: none;
          // }
        }

        &.short {
          display: none;
          // @include respond-to(mobile) {
          //   display: inline-block;
          // }
        }
      }
      .text:after {
        content:"";
        display: block;
        position: absolute;
        bottom:-5px;
        width: 0;
        height: 2px;
        background-color: #fff;
        @include transition(all .35s cubic-bezier(.23, 1, .32, 1));

        @include respond-to(mobile) {
          display: none;
        }
      }

      &:hover {
        .text:after {
          background-color: $drkblue;
          @include respond-to(mobile) { background-color: #fff; }
        }
        .icon-close {
          color:$drkblue;
          @include respond-to(mobile) { color: #fff; }
        }
      }
      
    }
    .icon-close {
      font-size: 12px;
      margin-left: 4px;
      vertical-align: baseline;
      opacity: 0;
      @include transition(opacity .35s cubic-bezier(.23, 1, .32, 1));

      @include respond-to(mobile) {
        font-size: 11px;
        display: none;
      }
    }
  }

  .social-nav {
    float: right;
    padding:30px;

    @include respond-to(mobile) {
      padding:15px 20px 20px 0;
    }

    @media all and (max-width: 568px) {
      padding:15px 16px 20px 0;
      
      display: flex;
      flex-wrap: wrap;
      max-width: 120px;
      justify-content: flex-end;
    }

    ul li.nav-text-item {
      vertical-align: initial;

      @media all and (max-width: 568px) {
        padding: 0 0 8px;
      }

      .icon-close {
        // font-size: 12px;
        margin-left: 0px;
        margin-right: 4px;
      }
    }

    ul li.nav-icon-item {
      margin-left: 12px;
      vertical-align: middle;
      display: inline-block;

      [class^="icon-"], [class*=" icon-"] {
        font-size: 17px;
      }
      .icon-snapchat { margin:0px 2px; font-size: 20px;}
      .icon-instagram { margin-top: 3px; }
      .icon-email { font-size: 14px; }
      .icon-twitter { font-size: 16px; }

      a {
        @include respond-to(mobile) {
          opacity: 1 !important;
        }
      }
    }
  }
}

.hamburger {
  position: fixed;
  top:22px;
  left:22px;
  z-index: 120;
  display: none;
  cursor: pointer;

  @include respond-to(mobile) {
    display: block;
  }

  &.active span {
    &:nth-child(1) {
      transform: rotate(45deg) translate(3px,3px);
      -webkit-transform: rotate(45deg) translate(3px,3px);
      -moz-transform: rotate(45deg) translate(3px,3px);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-45deg) translate(4px,-4px);
      -webkit-transform: rotate(-45deg) translate(4px,-4px);
      -moz-transform: rotate(-45deg) translate(4px,-4px);
    }
  }

  span {
    display: block;
    width: 20px;
    height:2px;
    background-color: #fff;
    margin:3px 0;
  }
}
