@font-face {
    font-family: 'averta_stdregular';
    src: url('../fonts/Averta/avertastd-regular-webfont.eot');
    src: url('../fonts/Averta/avertastd-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Averta/avertastd-regular-webfont.woff2') format('woff2'),
         url('../fonts/Averta/avertastd-regular-webfont.woff') format('woff'),
         url('../fonts/Averta/avertastd-regular-webfont.ttf') format('truetype'),
         url('../fonts/Averta/avertastd-regular-webfont.svg#averta_stdregular') format('svg');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'averta_stdsemibold';
    src: url('../fonts/Averta/avertastd-semibold-webfont.eot');
    src: url('../fonts/Averta/avertastd-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Averta/avertastd-semibold-webfont.woff2') format('woff2'),
         url('../fonts/Averta/avertastd-semibold-webfont.woff') format('woff'),
         url('../fonts/Averta/avertastd-semibold-webfont.ttf') format('truetype'),
         url('../fonts/Averta/avertastd-semibold-webfont.svg#averta_stdsemibold') format('svg');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'averta_stdbold';
    src: url('../fonts/Averta/avertastd-bold-webfont.eot');
    src: url('../fonts/Averta/avertastd-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Averta/avertastd-bold-webfont.woff2') format('woff2'),
         url('../fonts/Averta/avertastd-bold-webfont.woff') format('woff'),
         url('../fonts/Averta/avertastd-bold-webfont.ttf') format('truetype'),
         url('../fonts/Averta/avertastd-bold-webfont.svg#averta_stdbold') format('svg');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'averta_stdextrabold';
    src: url('../fonts/Averta/avertastd-extrabold-webfont.eot');
    src: url('../fonts/Averta/avertastd-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Averta/avertastd-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/Averta/avertastd-extrabold-webfont.woff') format('woff'),
         url('../fonts/Averta/avertastd-extrabold-webfont.ttf') format('truetype'),
         url('../fonts/Averta/avertastd-extrabold-webfont.svg#averta_stdextrabold') format('svg');
    font-weight: 800;
    font-style: normal;

}

// icons

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/Icons/icomoon.eot?gbyioz');
  src:  url('../fonts/Icons/icomoon.eot?gbyioz#iefix') format('embedded-opentype'),
    url('../fonts/Icons/icomoon.ttf?gbyioz') format('truetype'),
    url('../fonts/Icons/icomoon.woff?gbyioz') format('woff'),
    url('../fonts/Icons/icomoon.svg?gbyioz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e906";
}
.icon-toggle:before {
  content: "\e905";
}
.icon-tiktok:before {
  content: "\e902";
}
.icon-right-arrow:before {
  content: "\e903";
}
.icon-left-arrow:before {
  content: "\e904";
}
.icon-email:before {
  content: "\e900";
}
.icon-snapchat:before {
  content: "\e901";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin:before {
  content: "\eaca";
}