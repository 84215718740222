.subscribe-wrap {
  .heading-wrapper {
    margin: 0 auto 50px;
    max-width: 500px;

    p {
      font-size: emCal(20px);
      line-height: 34px;
      margin-top: 10px;

      @include respond-to(mobile) {
        font-size: emCal(18px);
        line-height: 22px;
      }
    }
  }
}

.subscribe-form {
  margin: 0 auto;
  max-width: 500px;
  text-align: left;

  .flex-inputs {
    display: flex;
    justify-content: center;

    @media all and (max-width: 568px) {
      display: block;
      // max-width: 400px;
      margin: 0 auto;
    }

    .subscribe-input-number {
      margin-left: -2px;

      @media all and (max-width: 568px) {
        margin-top: -2px;
        margin-left: 0;
      }
    }
  }

  .subscribe-inputs {
    input,
    select, {
      background-color: transparent;
      border: 2px solid white;
      border-radius: 0;
      color: white;
      height: 50px;
      font-size: emCal(16px);
      padding: 15px;
      width: 100%;

      &.mce_inline_error {
        border: 2px solid white !important;
      }
    }

    select {
      appearance: none;
    }

    .custom-select {
      position: relative;

      span {
        position: absolute;
        top: 13px;
        right: 12px;
        transform: rotate(90deg);
        font-size: 25px;
      }
    }

    label {
      @include AvertaSmBld();
      text-align: left;
      display: block;
      margin-bottom: 10px;
    }
    
    ::placeholder {
      color:rgba(255,255,255,0.65);
    }


    .subscribe-input-name {
      // width: 150px;
      margin-bottom: 20px;

      @media all and (max-width: 568px) {
        width: 100%;
      }

      input {
        @include AvertaReg();
      }
    }

    .subscribe-input-email {
      width: 250px;

      @media all and (max-width: 568px) {
        width: 100%;
      }

      input {
        @include AvertaReg();
      }
    }

    .subscribe-button {
      background-color: white;
      border: 0;
      color: $bgblue;
      height: 50px;
      font-size: emCal(14px);
      width: 100%;
      margin-top: 30px;

      @media all and (max-width: 568px) {
        width: 100%;
        // margin-top: 5px;
      }
    }
  }
}

#mc_embed_signup input.mce_inline_error,
#sk_embed_signup input.ske_inline_error {
  border: 2px solid white;
}


#mc_embed_signup div.mce_inline_error,
#sk_embed_signup div.ske_inline_error {
  @include AvertaReg();
  background-color: transparent !important;
  font-size: emCal(12px) !important;
  text-align: left !important;
  color: white !important;
}

#mce-responses,
#ske-responses {
  display: block;
  padding: 15px 15px 0;
  text-align: center;
}