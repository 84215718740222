/*==========================================================================
   COLORS
 ========================================================================== */
$black:#000;
$drkblue:#005173;
$bgblue: #4AA7C6;

/*==========================================================================
   MEDIA QUERIES // example @include respond-to(mobile) { }
 ========================================================================== */

  /*
    @include respond-to(mobile) {}
    @include respond-to(tablet) {}
    @include respond-to(desktopSm) {}
    @include respond-to(desktopLg) {}
  */

@mixin respond-to($media) {

  @if $media == desktop {
    $total-columns: 32;
    @media all and (min-width: 1024px) { @content; }
  }

  @if $media == tablet {
    $total-columns: 24;
    @media all and (min-width: 768px) and (max-width: 1023px) { @content; }
  }

  @if $media == mobile {
    $total-columns: 16;
    @media all and (max-width: 767px) { @content; }
  }
}

/*==========================================================================
   FONTS
 ========================================================================== */
@mixin AvertaReg() { font-family: 'averta_stdregular', Helvetica, sans-serif; font-weight: lighter; }
@mixin AvertaBld() { font-family: 'averta_stdbold', Helvetica, sans-serif; font-weight: lighter; }
@mixin AvertaSmBld() { font-family: 'averta_stdsemibold', Helvetica, sans-serif; font-weight: lighter; }
@mixin AvertaExBld() { font-family: 'averta_stdextrabold', Helvetica, sans-serif; font-weight: lighter; }

/*==========================================================================
   FUNCTIONS
 ========================================================================== */
$emBase: 16px; //default
$total-columns: 12;

// (em calculator)
@function emCal($pixelBasedNum){
  @return calc(($pixelBasedNum / $emBase) * 1em); // $pixelBasedNum / $emBase * 1em;
}

// column width in percent
@function makeColumn($numOfCols,$context:$total-columns) {
  @return ($numOfCols / $context) * 100%;
}

// calculate letter spacing from a design // https://scotch.io/tutorials/converting-photoshop-letter-spacing-to-css
@function emLetterSpace($designNum) {
  @return $designNum / 1000;
}

/*==========================================================================
   MIXINS
 ========================================================================== */

@mixin border-radius($radius) { -webkit-border-radius: $radius; -moz-border-radius: $radius; border-radius: $radius; }
@mixin box-sizing($type) { -webkit-box-sizing: $type; -moz-box-sizing: $type; box-sizing: $type; }
@mixin transition($properties) {-moz-transition: $properties; -webkit-transition: $properties; -o-transition: $properties; transition: $properties;}
@mixin transform($properties) {-webkit-transform: $properties; -moz-transform: $properties; -ms-transform: $properties; -o-transform: $properties; transform: $properties;}
@mixin fullscreen($position, $zIndex) {
  width:100%;
  height: 100%;
  top:0;
  left:0;
  position: $position;
  z-index: $zIndex;
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

// column width
@mixin column_width($numOfCols,$context:$total-columns) {
   width: makeColumn($numOfCols,$context);
}

// retina.. Higher display density
@mixin highdpi($ratio) {
  @media all and (-webkit-min-device-pixel-ratio: 2),
  all and (   min--moz-device-pixel-ratio: 2),
  all and (     -o-min-device-pixel-ratio: 2/1),
  all and (        min-device-pixel-ratio: 2),
  all and (                min-resolution: 192dpi),
  all and (                min-resolution: 2dppx) { @content; }
}