.home-wrap {
  width:100%;
  height: 100%;
  text-align: center;
  position: relative;
  @include transition(all .35s cubic-bezier(.23, 1, .32, 1));
  opacity: 0;
  overflow: hidden;

  &.open {
    overflow-y:auto;
    opacity: 1;
    @include transition(all .35s cubic-bezier(.23, 1, .32, 1));
  }

  .inner-wrapper {
    width:100%;
    height: 100%;
    display: table;
  }

  .content-wrap {
    width:100%;
    display: table-cell;
    vertical-align: middle;
    padding:100px 25px;
  }

  svg {
    height: 100%;
    width: auto;
  }

  h1 {
    height: 250px;
    margin-top:30px;
    opacity: 0;
    @include transition(all .7s cubic-bezier(.35, 1, .32, 1));

    &.animated {
      opacity: 1;
    }

    @media all and (min-width: 1300px) and (min-height: 700px) {
      height: 320px;
    }

    @include respond-to(tablet) {
      height: 220px;
    }

    @include respond-to(mobile) {
      height: 110px;
      margin-top: 0;
    }
  }

  h2 {
    opacity: 0;
    @include AvertaSmBld();
    font-size: emCal(24px);
    line-height: 30px;
    margin-top: 20px;
    @include transition(all .7s cubic-bezier(.35, 1, .32, 1));

    &.animated {
      opacity: 1;
    }

    @include respond-to(mobile) {
      font-size: emCal(18px);
      line-height: 24px;
      padding:0 20px;
    }
  }

  .icon-sam {
    font-size: 250px;

    @media all and (min-width: 1300px) and (min-height: 700px) {
      font-size: 320px;
    }

    @include respond-to(tablet) {
      font-size: 220px;
    }

    @include respond-to(mobile) {
      font-size: 110px;
    }
  }
}
