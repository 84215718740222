/* Base
----------------------------------------------*/
* { margin:0; padding: 0; outline:0; -webkit-margin-before: 0; -webkit-margin-after: 0; -webkit-margin-start: 0px; -webkit-margin-end: 0px; }
*, *:before, *:after {-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;}

html { height:100%; }
html.locked, body.locked { overflow: hidden; }

/* Body settings
----------------------------------------------*/
body { overflow: hidden; @include AvertaReg(); color: #fff; background-color:$bgblue; background-position: center top; background-repeat: no-repeat; background-size: cover; font-size: 100%; height:100%; -webkit-font-smoothing:antialiased; }
.bg-overlay {
  @include fullscreen(fixed, 1);
  @include transition(all .35s cubic-bezier(.23, 1, .32, 1));
  background-color:$bgblue;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: .8;
    visibility: visible;
    @include transition(all .35s cubic-bezier(.23, 1, .32, 1));
  } 
}

.main { width: 100%; height: 100%; }

p, li, dd { line-height: 1.5em; }
em, i, cite {  }
strong, b { }
sup { }
sub { }
mark { }
dt {}
dd { }

blockquote { }
::selection { background-color:#ddd; }

::-webkit-input-placeholder { color: #ebebeb; }
:-moz-placeholder {color: #ebebeb; }
::-moz-placeholder {color: #ebebeb; }
:-ms-input-placeholder {color: #ebebeb; }

/* Headings
----------------------------------------------*/

// h1, h2, h3, h4, h5 ,h6 { color:#fff }
h1 {font-size: emCal(50px); line-height: 60px; }
h2 {font-size: emCal(50px); line-height: 55px; @include AvertaExBld();
  @include respond-to(mobile) { font-size: emCal(40px); line-height: 45px; }
}
h3 {font-size: emCal(20px); line-height: 30px; @include AvertaSmBld();
  @include respond-to(mobile) {font-size: emCal(16px); line-height: 22px; }
}
h4 {font-size: emCal(20px); line-height: 30px;
  @include respond-to(mobile) {font-size: emCal(16px); line-height: 22px; }
}
h5 {font-size: emCal(18px); line-height: 26px;
  @include respond-to(mobile) {font-size: emCal(16px); line-height: 22px; }
}
h6 {font-size: emCal(16px); line-height: 24px; }

p {font-size: emCal(25px); line-height: 35px; color:#fff;
  @include respond-to(mobile) { font-size: emCal(18px); line-height: 26px; }
}
.avboldup { font-size: 14px; line-height: 14px; letter-spacing: .5px; @include AvertaBld(); text-transform: uppercase;
  @include respond-to(mobile) {font-size: emCal(13px); line-height: 13px; }
}

/* links
----------------------------------------------*/
a, a:link, a:visited {text-decoration: none; color: #fff; @include transition(all .35s cubic-bezier(.23, 1, .32, 1)); }
a:hover, a:active {text-decoration: none; color: $drkblue;
  @include respond-to(mobile) { color: #fff; }
}

/* Blockquote
----------------------------------------------*/
blockquote {font-size: emCal(35px); line-height: 45px; text-align: center; color:#fff; }

/* Media 
----------------------------------------------*/
img, video {width: 100%; height: auto; margin: 0 auto; display: block; }
figcaption {font-size: 85%; }

/* Lists
----------------------------------------------*/
ol, ul {
	list-style: none;
}

/* Page wrap
----------------------------------------------*/
.page-wrap {
  width:100%;
  height: 0;
  top:100%;
  left:0;
  position: absolute;
  z-index: 10;
  overflow: hidden;

  @include transition(all .5s cubic-bezier(.23, 1, .32, 1));
  text-align: center;

  &.open {
    overflow: auto;
    -webkit-overflow-scrolling:touch;
    top:0;
    height: 100%;
  }

  .inner-wrapper {
    width:100%;
    position: relative;
    top:0;
    padding:200px 25px 50px;

    @include respond-to(mobile) {
      padding:120px 25px 50px;
      // margin-bottom: 150px;
    }
  }
}