.press-wrap,
.awards-wrap,
.bragging-rights-wrap {
  h2 {
    margin-bottom: 120px;

    @include respond-to(mobile) {
      margin-bottom: 80px;
    }
  }
  h3 {
    @include AvertaExBld();
    font-size: emCal(20px);
    line-height: 25px;
    height: 70px;

    @include respond-to(mobile) {
      font-size: emCal(16px);
      line-height: 20px;
    }
  }
  p {
    font-size: emCal(16px);
    line-height: 22px;
    margin-top: 20px;

    @include respond-to(mobile) {
      font-size: emCal(14px);
      line-height: 20px;
    }
  }

  .align-wrap {
    width: 100%;
    height: 100%;
    display: table;
    @include respond-to(mobile) {
      display: block;
    }
  }
  .align {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    @include respond-to(mobile) {
      display: block;
    }
  }

  .press-desc,
  .awards-desc {
    max-width: 1100px;
    margin:0 auto 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

    .pdesc,
    .adesc {
      width: 200px;
      display: inline-block;
      vertical-align: top;
      padding:0 10px 50px;

      @include respond-to(mobile) {
        width: 100%;
        max-width: 250px;
        padding:0 25px 35px;
      }
    }
    .pimage,
    .aimage {
      width: 100%;
      height: 80px;

      @include respond-to(mobile) {
        height: auto;
        padding:10px 0;
      }
    }
    img {
      width: auto;
      max-width: 125px;
      max-height: 70px;
      margin:0 auto;
    }
  }
}