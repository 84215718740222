.roles-wrap {
  h3 {
    display: inline-block;
    @include AvertaReg();
    border-bottom: solid 1px #fff;
    margin-bottom: 10px;
  }
  ul {
    margin-bottom: 70px;

    li {
      @include AvertaExBld();
      font-size: emCal(50px);
      line-height: 62px;

      @include respond-to(mobile) {
        font-size: emCal(35px);
        line-height: 45px;
      }

      a {
        position: relative;

        &:hover {
          .icon-ext-link {
            opacity: 1;
            right:-40px;
          }
        }
        .icon-ext-link {
          color:$drkblue;
          opacity: 0;
          font-size: 18px;
          vertical-align: middle;
          position: absolute;
          top: 25px;
          right: -20px;
          @include transition(all .5s cubic-bezier(.23, 1, .32, 1));

          @include respond-to(mobile) {
            display: none;
          }
        }
      }
    }
  }
}

// toggle
.toggle-roles {
  width:100%;
  position: fixed;
  // top:100%;
  top:0;
  left:0;
  z-index: 9999;
  text-align: center;
  @include transition(all .5s cubic-bezier(.23, 1, .32, 1));

  &.active {
    top:0;
    opacity: 1 !important;
    
    .toggle-wrap { top:0; }
    .icon-toggle,
    .icon-toggle:hover { @include transform(rotate(45deg)); color:#fff; }
  }
  .toggle-wrap {
    position: absolute;
    // width:100px;
    // left:50%;
    // bottom:0;
    // margin-left: -50px;
    // padding:25px 30px 30px;
    top:0;
    left:0;
    padding:29px 30px 30px 25px;
    @include transition(all .5s cubic-bezier(.23, 1, .32, 1));

    @include respond-to(mobile) {
      padding:17px 25px 20px 15px;
      // width:80px;
      // margin-left: -40px;
    }
  }
  .icon-toggle {
    font-size: 30px;
    // font-size: 40px;
    cursor: pointer;
    transform-origin: center center;
    @include transition(all .5s cubic-bezier(.23, 1, .32, 1));

    @include respond-to(mobile) {
      // font-size: 30px;
      font-size: 20px;
    }
  }
}